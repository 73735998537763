;(function() {
    'use strict';

    sigma.utils.pkg('sigma.canvas.edgehovers');

    /** A.G.
     * This edge renderer will display edges as tree branches.
     *
     * @param  {object}                   edge         The edge object.
     * @param  {object}                   source node  The edge source node.
     * @param  {object}                   target node  The edge target node.
     * @param  {CanvasRenderingContext2D} context      The canvas context.
     * @param  {configurable}             settings     The settings function.
     */

  sigma.canvas.edgehovers.branch = function(edge, source, target, context, settings) {

    var color = edge.color,
    prefix = settings('prefix') || '',
    size = (settings('edgeHoverSizeRatio')/2.5) * (edge[prefix + 'size'] || 1),
    edgeColor = settings('edgeColor'),
    defaultNodeColor = settings('defaultNodeColor'),
    defaultEdgeColor = settings('defaultEdgeColor'),
    cp = {},
    sSize = source[prefix + 'size'],
    x1 = source[prefix + 'x'],
    y1 = source[prefix + 'y'],
    x2 = target[prefix + 'x'],
    y2 = target[prefix + 'y'];

    cp = (source.id === target.id) ?
    sigma.utils.getSelfLoopControlPoints(x1, y1, sSize) :
    sigma.utils.getQuadraticControlPoint(x1, y1, x2, y2);

    if (!color)
        switch (edgeColor) {
            case 'source':
            color = source.color || defaultNodeColor;
            break;
            case 'target':
            color = target.color || defaultNodeColor;
            break;
            default:
            color = defaultEdgeColor;
            break;
        }

    if (settings('edgeHoverColor') === 'edge') {
        color = edge.hover_color || color;
    } else {
        color = edge.hover_color || settings('defaultEdgeHoverColor') || color;
    }

    if(edge.type === 'branch'){

        context.lineWidth = size;
        context.strokeStyle = color;
        context.fillStyle = color;
        context.beginPath();


        var dX = x2 - x1;
        var dY = y2 - y1;

        var angle = Math.atan2(dY, dX);
        var degrees =  angle * 180/Math.PI ;

        var cx1 = (x1 + x2) / 2 + (y2 - y1) / 4;
        var cy1= (y1 + y2) / 2 + (x1 - x2) / 4;

        // ARCO
        var sArcDesPues = 100;
        var sArcAntes = 100;
        var startAngleDeg = (degrees - sArcAntes) > 0? (degrees - sArcAntes) : (360 + (degrees-sArcAntes));
        var endAngleDeg = (degrees + sArcDesPues) <= 360? (degrees+sArcDesPues) : (degrees - 360);

        var startAngle = startAngleDeg * (Math.PI / 180);
        var endAngle = endAngleDeg * (Math.PI / 180);

        context.arc(x1,y1,10,startAngle,endAngle,false);

        // CURVA GRANDE
        context.quadraticCurveTo(cx1, cy1, x2, y2);

        var xArc = x1+Math.cos(startAngle)*10;
        var yArc = y1+Math.sin(startAngle)*10;
        context.quadraticCurveTo(cx1, cy1, xArc, yArc);

        context.stroke();
        context.fill();

    }



  };

  })();
