;(function(undefined) {
  'use strict';

  if (typeof sigma === 'undefined')
    throw 'sigma is not declared';

  // Initialize packages:
  sigma.utils.pkg('sigma.canvas.edges.labels');

  var s = sigma;

  /**
   * This label renderer will just display the label on the curve of the edge.
   * The label is rendered at half distance of the edge extremities, and is
   * always oriented from left to right on the top side of the curve.
   *
   * @param  {object}                   edge         The edge object.
   * @param  {object}                   source node  The edge source node.
   * @param  {object}                   target node  The edge target node.
   * @param  {CanvasRenderingContext2D} context      The canvas context.
   * @param  {configurable}             settings     The settings function.
   */


  function curveHelper(x1, y1, x2, y2, x3, y3, x4, y4){
    var tx1, ty1, tx2, ty2, tx3, ty3, tx4, ty4;
    var a,b,c,u;
    var vec,currentPos,vec1,vect;
    vec = {x:0,y:0};
    vec1 = {x:0,y:0};
    vect = {x:0,y:0};
    var quad = false;
    currentPos = 0;
    var currentDist = 0;
    if(x4 === undefined || x4 === null){
        quad = true;
        x4 = x3;
        y4 = y3;
    }
    var estLen = Math.sqrt((x4 - x1) * (x4 - x1) + (y4 - y1) * (y4 - y1));
    var onePix = 1 / estLen;
    function posAtC(c){
        tx1 = x1; ty1 = y1;
        tx2 = x2; ty2 = y2;
        tx3 = x3; ty3 = y3;
        tx1 += (tx2 - tx1) * c;
        ty1 += (ty2 - ty1) * c;
        tx2 += (tx3 - tx2) * c;
        ty2 += (ty3 - ty2) * c;
        tx3 += (x4 - tx3) * c;
        ty3 += (y4 - ty3) * c;
        tx1 += (tx2 - tx1) * c;
        ty1 += (ty2 - ty1) * c;
        tx2 += (tx3 - tx2) * c;
        ty2 += (ty3 - ty2) * c;
        vec.x = tx1 + (tx2 - tx1) * c;
        vec.y = ty1 + (ty2 - ty1) * c;    
        return vec;
    }
    function posAtQ(c){
        tx1 = x1; ty1 = y1;
        tx2 = x2; ty2 = y2;
        tx1 += (tx2 - tx1) * c;
        ty1 += (ty2 - ty1) * c;
        tx2 += (x3 - tx2) * c;
        ty2 += (y3 - ty2) * c;
        vec.x = tx1 + (tx2 - tx1) * c;
        vec.y = ty1 + (ty2 - ty1) * c;
        return vec;
    }    
    function forward(dist){
        var step;
        helper.posAt(currentPos);

        while(currentDist < dist){
            vec1.x = vec.x;
            vec1.y = vec.y;            
            currentPos += onePix;
            helper.posAt(currentPos);
            currentDist += step = Math.sqrt((vec.x - vec1.x) * (vec.x - vec1.x) + (vec.y - vec1.y) * (vec.y - vec1.y));

        }
        currentPos -= ((currentDist - dist) / step) * onePix
        currentDist -= step;
        helper.posAt(currentPos);
        currentDist += Math.sqrt((vec.x - vec1.x) * (vec.x - vec1.x) + (vec.y - vec1.y) * (vec.y - vec1.y));
        return currentPos;
    }
    
    function tangentQ(pos){
        a = (1-pos) * 2;
        b = pos * 2;
        vect.x = a * (x2 - x1) + b * (x3 - x2);
        vect.y = a * (y2 - y1) + b * (y3 - y2);       
        u = Math.sqrt(vect.x * vect.x + vect.y * vect.y);
        vect.x /= u;
        vect.y /= u;        
    }
    function tangentC(pos){
        a  = (1-pos)
        b  = 6 * a * pos;       
        a *= 3 * a;                  
        c  = 3 * pos * pos; 
        vect.x  = -x1 * a + x2 * (a - b) + x3 * (b - c) + x4 * c;
        vect.y  = -y1 * a + y2 * (a - b) + y3 * (b - c) + y4 * c;
        u = Math.sqrt(vect.x * vect.x + vect.y * vect.y);
        vect.x /= u;
        vect.y /= u;
    }  
    var helper = {
        vec : vec,
        vect : vect,
        forward : forward,
    }
    if(quad){
        helper.posAt = posAtQ;
        helper.tangent = tangentQ;
    }else{
        helper.posAt = posAtC;
        helper.tangent = tangentC;
    }
    return helper
}
 
 
 var textOnCurve = function(ctx,text,offset,x1,y1,x2,y2,x3,y3,x4,y4){
    ctx.save();
    ctx.textAlign = "center";

   // ctx.translate(x2, y2);
    //ctx.rotate(angle);

    var widths = [];
    for(var i = 0; i < text.length; i ++){
        widths[widths.length] = ctx.measureText(text[i]).width;
    }
    var ch = curveHelper(x1,y1,x2,y2,x3,y3,x4,y4);
    var pos = offset;
    var cpos = 0;

    for(var i = 0; i < text.length; i ++){
        pos += widths[i] / 2;
        cpos = ch.forward(pos);
        ch.tangent(cpos);
        ctx.setTransform(ch.vect.x, ch.vect.y, -ch.vect.y, ch.vect.x, ch.vec.x, ch.vec.y);
        ctx.fillText(text[i],0,0);     

        pos += widths[i] / 2;
    }
    ctx.restore();
}

sigma.canvas.edges.labels.branch = function(edge, source, target, context, settings) {      
 
  sigma.canvas.edges.labels.curve(edge, source, target, context, settings);
 

/*  if (typeof edge.label !== 'string')
  return;

var prefix = settings('prefix') || '',
    size = edge[prefix + 'size'] || 1;

if (size < settings('edgeLabelThreshold'))
  return;

var fontSize,
    sSize = source['renderer1:size'], //source[prefix + 'size'],
    sX = source['renderer1:x'], //source[prefix + 'x'],
    sY = source['renderer1:y'], //source[prefix + 'y'],
    tX = target['renderer1:x'], //target[prefix + 'x'],
    tY = target['renderer1:y'], //target[prefix + 'y'],
    count = edge.count || 0,
    dX = tX - sX,
    dY = tY - sY,
    sign = (sX < tX) ? 1 : -1,
    cp = {},
    c,
    angle,
    t = 0.5;  //length of the curve

if (source.id === target.id) {
  cp = sigma.utils.getSelfLoopControlPoints(sX, sY, sSize, count);
  c = sigma.utils.getPointOnBezierCurve(
    t, sX, sY, tX, tY, cp.x1, cp.y1, cp.x2, cp.y2
  );
  angle = Math.atan2(1, 1); // 45°
} else {
  cp = sigma.utils.getQuadraticControlPoint(sX, sY, tX, tY, count);
  c = sigma.utils.getPointOnQuadraticCurve(t, sX, sY, tX, tY, cp.x, cp.y);
  angle = Math.atan2(dY * sign, dX * sign);
}


// The font size is sublineraly proportional to the edge size, in order to
// avoid very large labels on screen.
// This is achieved by f(x) = x * x^(-1/ a), where 'x' is the size and 'a'
// is the edgeLabelSizePowRatio. Notice that f(1) = 1.
// The final form is:
// f'(x) = b * x * x^(-1 / a), thus f'(1) = b. Application:
// fontSize = defaultEdgeLabelSize if edgeLabelSizePowRatio = 1
fontSize = (settings('edgeLabelSize') === 'fixed') ?
  settings('defaultEdgeLabelSize') :
  settings('defaultEdgeLabelSize') *
  size *
  Math.pow(size, -1 / settings('edgeLabelSizePowRatio'));

context.save();


if (edge.active) {
  context.font = [
    settings('activeFontStyle'),
    fontSize + 'px',
    settings('activeFont') || settings('font')
  ].join(' ');

  context.fillStyle =
    settings('edgeActiveColor') === 'edge' ?
    (edge.active_color || settings('defaultEdgeActiveColor')) :
    settings('defaultEdgeLabelActiveColor');
}
else {
  context.font = [
    settings('fontStyle'),
    fontSize + 'px',
    settings('font')
  ].join(' ');

  context.fillStyle =
    (settings('edgeLabelColor') === 'edge') ?
    (edge.color || settings('defaultEdgeColor')) :
    settings('defaultEdgeLabelColor');
}


context.textAlign = 'center';
context.textBaseline = 'alphabetic';

context.translate(c.x, c.y);
context.rotate(angle);

context.restore();
context.fillStyle  = 'black';



context.fillStyle  = 'black';


  var x1 = sX, y1= sY, x2 = tX, y2 = tY;
  
  textOnCurve(context,edge.label,1,x1,y1,c.x,c.y,x2,y2); 
*/

};

}).call(this);

