;(function() {
  'use strict';

  sigma.utils.pkg('sigma.canvas.edges');

sigma.canvas.edges.branch = function(edge, source, target, context, settings) {

    var color = edge.color,
    prefix = settings('prefix') || '',
    size = edge[prefix + 'size'] || 1,
    edgeColor = settings('edgeColor'),
    defaultNodeColor = settings('defaultNodeColor'),
    defaultEdgeColor = settings('defaultEdgeColor'),
    x1 = source[prefix + 'x'],
    y1 = source[prefix + 'y'],
    x2 = target[prefix + 'x'],
    y2 = target[prefix + 'y'];


    if (!color)
    switch (edgeColor) {
    case 'source':
        color = source.color || defaultNodeColor;
        break;
    case 'target':
        color = target.color || defaultNodeColor;
        break;
    default:
        color = defaultEdgeColor;
        break;
    }


    context.beginPath();
    context.strokeStyle = color;
    context.fillStyle = color;

    var dX = x2 - x1;
    var dY = y2 - y1;

    var angle = Math.atan2(dY, dX);
    var degrees =  angle * 180/Math.PI ;

    var cx1 = (x1 + x2) / 2 + (y2 - y1) / 4;
    var cy1= (y1 + y2) / 2 + (x1 - x2) / 4;

    // ARCO
    var sArcDesPues = 100;
    var sArcAntes = 100;
    var startAngleDeg = (degrees - sArcAntes) > 0? (degrees - sArcAntes) : (360 + (degrees-sArcAntes));
    var endAngleDeg = (degrees + sArcDesPues) <= 360? (degrees+sArcDesPues) : (degrees - 360);

    var startAngle = startAngleDeg * (Math.PI / 180);
    var endAngle = endAngleDeg * (Math.PI / 180);

    context.arc(x1,y1,10,startAngle,endAngle,false);

    // CURVA GRANDE
    context.quadraticCurveTo(cx1, cy1, x2, y2);

    var xArc = x1+Math.cos(startAngle)*10;
    var yArc = y1+Math.sin(startAngle)*10;
    context.quadraticCurveTo(cx1, cy1, xArc, yArc);

    context.stroke();
    context.fill();

};

})();




